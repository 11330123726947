import useExperiment from 'src/hooks/useExperiment';

import ApplicantSuitableVacanciesPage from 'src/pages/ApplicantSuitableVacancies/components/ApplicantSuitableVacanciesPage';
import ApplicantSuitableVacanciesPageRedesign from 'src/pages/ApplicantSuitableVacancies/components/redesign/ApplicantSuitableVacanciesPage';

const ApplicantSuitableVacanciesRoute = () => {
    const isMagritte = useExperiment('magritte_resume_builder');
    if (isMagritte) {
        return <ApplicantSuitableVacanciesPageRedesign />;
    }
    return <ApplicantSuitableVacanciesPage />;
};

export default ApplicantSuitableVacanciesRoute;
