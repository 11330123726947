import { ComponentType } from 'react';

import {
    Avatar,
    Button,
    Card,
    GridColumn,
    GridRow,
    Text,
    useBreakpoint,
    VSpacing,
    VSpacingContainer,
} from '@hh.ru/magritte-ui';
import { CheckCircleFilledSize24 } from '@hh.ru/magritte-ui/icon';
import { Link } from '@hh.ru/redux-spa-middleware';
import { format } from 'bloko/common/trl';

import DefaultLayout from 'src/app/layouts/MagritteLayouts/DefaultLayout';
import PageLayout from 'src/app/layouts/PageLayout';
import RelatedVacancies from 'src/components/RelatedVacancies';
import translation from 'src/components/translation';
import { useTopLevelSite } from 'src/hooks/useSites';

import ApplicantSuitableVacanciesPageSuggest from 'src/pages/ApplicantSuitableVacancies/components/redesign/ApplicantSuitableVacanciesPageSuggest';

import styles from './styles.less';

const TrlKeys = {
    title: 'suitableVacancies.title',
    cardTitle: 'resume.suitable-vacancies.card.title',
    cardDescription: 'resume.suitable-vacancies.card.description',
    cardButton: 'resume.suitable-vacancies.card.button',
};

const ApplicantSuitableVacanciesPage: ComponentType = translation(({ trls }) => {
    const { isMobile } = useBreakpoint();
    const topLevelSite = useTopLevelSite();

    const title = (
        <Text typography="title-5-semibold" data-qa="suitable-vacancies-card-title">
            {trls[TrlKeys.cardTitle]}
        </Text>
    );
    const description = (
        <Text typography="paragraph-2-regular" style="secondary" data-qa="suitable-vacancies-card-description">
            {format(trls[TrlKeys.cardDescription], { '{0}': topLevelSite })}
        </Text>
    );
    const linkButton = (
        <Button
            Element={Link}
            stretched={isMobile}
            to="/applicant/resumes"
            mode="primary"
            style="accent"
            data-qa="suitable-vacancies-card-button"
        >
            {trls[TrlKeys.cardButton]}
        </Button>
    );

    const avatar = (
        <Avatar
            size={48}
            icon={<CheckCircleFilledSize24 initial="positive" />}
            aria-label="success"
            style="positive"
            mode="icon"
            shapeCircle
            data-qa="suitable-vacancies-card-mark"
        />
    );

    return (
        <PageLayout layout={DefaultLayout} title={trls[TrlKeys.title]}>
            {isMobile ? (
                <VSpacingContainer default={24}>
                    <Card padding={24} borderRadius={24} showBorder stretched>
                        <div className={styles.mobileCardContent}>
                            {avatar}
                            <VSpacing default={32} />
                            {title}
                            <VSpacing default={8} />
                            {description}
                            <VSpacing default={24} />
                            {linkButton}
                        </div>
                    </Card>
                    <ApplicantSuitableVacanciesPageSuggest />
                    <RelatedVacancies />
                </VSpacingContainer>
            ) : (
                <GridRow>
                    <GridColumn xs={0} m={7}>
                        <Card padding={24} borderRadius={24} showBorder stretched>
                            <div className={styles.desktopCardContent}>
                                <VSpacingContainer default={8}>
                                    {title}
                                    {description}
                                </VSpacingContainer>
                                <div className={styles.desktopAvatarWrapper}>{avatar}</div>
                            </div>
                            <VSpacing default={24} />
                            {linkButton}
                        </Card>
                        <VSpacing default={40} />
                        <RelatedVacancies />
                    </GridColumn>
                    <GridColumn xs={0} l={1} />
                    <GridColumn xs={0} m={5} l={4}>
                        <ApplicantSuitableVacanciesPageSuggest />
                    </GridColumn>
                </GridRow>
            )}
        </PageLayout>
    );
});

export default ApplicantSuitableVacanciesPage;
