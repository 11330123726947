import { ComponentType, useMemo } from 'react';

import { Button, Card, Cell, ProgressBar, Text, Title, VSpacing, VSpacingContainer } from '@hh.ru/magritte-ui';
import { BriefcaseOutlinedSize24, ChevronRightOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { Link, usePush } from '@hh.ru/redux-spa-middleware';

import { ApplicantServices } from 'src/components/ApplicantServices/common';
import translation from 'src/components/translation';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';
import { ResumeField, Resume } from 'src/models/resume/resume.types';

import { BLOCKS, getFields } from 'src/pages/ApplicantSuitableVacancies/components/redesign/utils';

import styles from './styles.less';

const TrlKeys = {
    proftest: 'resume.suitable-vacancies.proftest',
    suggestTitle: 'resume.suitable-vacancies.suggest.title',
    suggestHint: 'resume.suitable-vacancies.suggest.hint',
    fields: {
        photo: 'suitableVacancies.suggest.photo',
        keySkills: 'suitableVacancies.suggest.keySkills',
        skills: 'suitableVacancies.suggest.skills',
        salary: 'suitableVacancies.suggest.salary',
        metro: 'suitableVacancies.suggest.metro',
        language: 'suitableVacancies.suggest.language',
        additionalEducation: 'suitableVacancies.suggest.additionalEducation',
        attestationEducation: 'suitableVacancies.suggest.attestationEducation',
        certificates: 'suitableVacancies.suggest.certificate',
        birthday: 'suitableVacancies.suggest.birthday',
        gender: 'suitableVacancies.suggest.gender',
        personalSite: 'suitableVacancies.suggest.personalSite',
        experience: 'suitableVacancies.suggest.experience',
        recommendation: 'suitableVacancies.suggest.recommendation',
        portfolio: 'suitableVacancies.suggest.portfolio',
    },
};

const ApplicantSuitableVacanciesPageSuggest: ComponentType = translation(({ trls }) => {
    const push = usePush();
    const resume = useSelector((state) => state.resume as Resume);
    const metroCities = useSelector((state) => state.metroCities);
    const applicantResumesIdentifiers = useSelector((state) => state.applicantResumesIdentifiers);
    const resumeIdsProfTestAttached = useSelector((state) => state.resumeIdsProfTestAttached);
    const applicantPaymentServices = useSelector((state) => state.applicantPaymentServices);
    const isZarplata = useIsZarplataPlatform();
    const showProftest =
        !isZarplata &&
        !!applicantResumesIdentifiers.some((identifier) => !resumeIdsProfTestAttached.includes(identifier['@id'])) &&
        applicantPaymentServices.includes(ApplicantServices.CareerGuidance);
    const fields = useMemo(() => getFields(resume, metroCities), [resume, metroCities]);

    return (
        <VSpacingContainer default={16}>
            <Card padding={24} showBorder borderRadius={24} data-qa="suitable-vacancies-suggest-card">
                <Title Element="h3" size="extra-small">
                    {trls[TrlKeys.suggestTitle]}
                </Title>
                <VSpacing default={24} />
                <ProgressBar segments={BLOCKS.length} progress={BLOCKS.length - fields.length} />
                <VSpacing default={24} />
                <Text typography="label-2-regular" style="secondary">
                    {trls[TrlKeys.suggestHint]}
                </Text>
                <VSpacing default={12} />
                <div className={styles.suggestArray}>
                    {fields.map((field) => (
                        <Button
                            data-qa={`suitable-vacancies-suggest-item-${String(field)}`}
                            Element={Link}
                            to={`/applicant/resumes/edit/${(resume[field] as ResumeField).block}?resume=${
                                resume.hash
                            }#${String(field)}`}
                            style="neutral"
                            mode="secondary"
                            size="small"
                            key={String(field)}
                        >
                            {trls[TrlKeys.fields[field as keyof typeof TrlKeys.fields]]}
                        </Button>
                    ))}
                </div>
            </Card>
            {showProftest && (
                <Card
                    padding={24}
                    showBorder
                    borderRadius={24}
                    actionCard
                    data-qa="suitable-vacancies-proforientation-card"
                    onClick={() =>
                        push(
                            resume?.proftest.value?.resultsReceived
                                ? '/applicant/proforientation'
                                : '/article/proforientation_promo'
                        )
                    }
                >
                    <Cell avatar={<BriefcaseOutlinedSize24 />} right={<ChevronRightOutlinedSize24 />}>
                        <Text typography="label-2-regular">{trls[TrlKeys.proftest]}</Text>
                    </Cell>
                </Card>
            )}
        </VSpacingContainer>
    );
});

export default ApplicantSuitableVacanciesPageSuggest;
