import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

import { RELATED_VACANCIES_TYPES, RelatedVacanciesType } from 'src/components/RelatedVacancies/relatedVacanciesTypes';

const TrlKeys = {
    titleSimilar: 'vacancy.similarVacancy.title',
    titleSuitable: 'vacancy.suitable.title',
};

const RelatedVacanciesTitle: TranslatedComponent<{ type: RelatedVacanciesType }> = ({ type, trls }) => {
    if (type === RELATED_VACANCIES_TYPES.SUITABLE) {
        return <>{trls[TrlKeys.titleSuitable]}</>;
    }
    return <>{trls[TrlKeys.titleSimilar]}</>;
};

export default translation(RelatedVacanciesTitle);
