import { Resume, ResumeField, ResumeSalary } from 'src/models/resume/resume.types';

export const BLOCKS: Array<keyof Resume> = [
    'photo',
    'keySkills',
    'skills',
    'salary',
    'metro',
    'language',
    'additionalEducation',
    'attestationEducation',
    'certificates',
    'birthday',
    'gender',
    'personalSite',
    'experience',
    'recommendation',
    'portfolio',
];

export const getFields = (resume: Resume, metroCities: string[]): Array<keyof Resume> => {
    return BLOCKS.reduce((acc, name) => {
        const value = (resume[name] as ResumeField)?.value;
        let show = !value;
        if (
            [
                'photo',
                'recommendation',
                'portfolio',
                'personalSite',
                'experience',
                'additionalEducation',
                'attestationEducation',
                'keySkills',
            ].includes(name)
        ) {
            show = !(value as Array<unknown>).length;
        }
        if (name === 'language') {
            show = (value as Array<unknown>).length < 2;
        }
        if (name === 'salary') {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            show = !(value as ResumeSalary)?.amount;
        }
        if (name === 'metro') {
            show = !value && metroCities.includes(`${String(resume.area.value?.id)}`);
        }
        if (show) {
            acc.push(name);
        }
        return acc;
    }, new Array<keyof Resume>());
};
